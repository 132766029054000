<template>
  <div class="popup">
    <div class="popup__area">
      <h2 class="popup__area__title">{{ title }}</h2>
      <p class="popup__area__explain">{{ message }}</p>
      <v-btn text class="popup__area__btn--left" @click="$emit('close', false)">Hủy</v-btn>
      <v-btn text class="popup__area__btn--right" @click="move()">Tiếp tục</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // ポップアップに表示するタイトル
    title: {
      type: String,
      required: true
    },
    // ポップアップに表示するメッセージ
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * 親コンポーネントのback関数を実行
     */
    move () {
      this.$emit('close', true)
      this.$emit('back')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  min-height: 100vh;
  width: 100vw;
  background-color: rgba(74, 74, 74, 0.7);
  z-index: 100;
  &__area {
    min-height: 256px;
    width: 85vw;
    min-width: 296px;
    padding: 40px 30px;
    background-color: white;
    text-align: center;
    box-sizing: border-box;
    &__title {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      color: #393939;
    }
    &__explain {
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 14px;
      color: #707070;
      text-align: left;
    }
    &__btn {
      margin-top: 28px;
      text-transform: none;
      font-size: 20px;
      font-weight: bold;
      color: #FF9534;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
        padding: 0;
      }
      &--left {
        @extend .popup__area__btn;
        float: left;
      }
      &--right {
        @extend .popup__area__btn;
        float: right;
      }
    }
  }
}
</style>
