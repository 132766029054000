<template>
  <div class="exam-question">
    <div v-if="!isProcessing">
      <header class="exam-question__header">
        <v-btn text class="exam-question__header__back" @click="isStart ? backPopup() : back()">
          <v-icon class="exam-question__header__back__icon">mdi-arrow-left</v-icon>
        </v-btn>
        <h1 class="exam-question__header__title">{{ isStart ? (num + 1) + ' / ' + Object.keys(questions).length : 'Bài thi' }}</h1>
      </header>
      <exam-start v-if="!isStart" :examName="exam.name" @start="startExam" />
      <exam-judge v-if="isStart && isAnswered" :answer="answerHistory[shuffleKeys[num]]" :question="question" @next="nextQuestion" />
      <exam-content v-if="isStart" :question="question" @pushAnswer="pushAnswer" />
      <popup v-if="openPopup"
      title='Bạn có chắc là muốn rời khỏi trang?'
      message='Nếu bạn rời khỏi trang, thông tin đăng ký sẽ không được lưu lại.'
      @close="closePopup" @back="back"/>
    </div>
    <!-- 処理中 -->
    <processing v-if="isProcessing" />
  </div>
</template>

<script>
import array from '@/assets/lib/array'

import ExamStart from '@/components/exam/start'
import ExamContent from '@/components/exam/content'
import ExamJudge from '@/components/exam/judge'
import Popup from '@/components/common/backPopup'
import Processing from '@/components/common/processing'

export default {
  mixins: [array],
  name: 'exam-question',
  components: { ExamStart, ExamContent, ExamJudge, Popup, Processing },
  data () {
    return {
      // 警告ポップアップがオープン中か
      openPopup: false,
      // シャッフルされた問題のID配列
      shuffleKeys: [],
      // 現在の問題番号
      num: 0,
      // 解答履歴
      answerHistory: {},
      // 試験を開始したかどうか
      isStart: false,
      // 解答したかどうか
      isAnswered: false,
      // 処理中かどうか
      isProcessing: true
    }
  },
  /**
   * 初回アクセス時の取得処理
   * データがstoreに格納されていなければ下記のフローで取得する
   * (1) 試験ID(eid)に紐づく試験情報(exams)と問題情報(questions)と結果情報(results)を取得
   */
  async mounted () {
    // 問題数
    const QUESTION_AMOUNT = 20

    // (1) 試験ID(eid)に紐づく試験情報(exams)と問題情報(questions)を取得
    const promises = []
    // 試験情報をチェック
    if (!this.$store.getters['exams/exam'](this.eid)) promises.push(this.$store.dispatch('exams/getExam', this.eid))
    // 試験問題情報をチェック
    if (!this.$store.getters['questions/questions'](this.eid)) promises.push(this.$store.dispatch('questions/getQuestions', this.eid))
    // 試験結果情報をチェック
    if (!this.$store.getters['results/result'](this.eid)) promises.push(this.$store.dispatch('results/getResult', { eid: this.eid, uid: this.uid }))
    if (promises.length > 0) await Promise.all(promises)

    // 試験がない、試験問題が20問ではない場合は404へ遷移
    if (!this.$store.getters['exams/exam'](this.eid) ||
    !this.$store.getters['questions/questions'](this.eid) ||
    Object.keys(this.$store.getters['questions/questions'](this.eid)).length !== QUESTION_AMOUNT) {
      this.$router.push({ name: 'NotFound' })
      return 0
    }

    // 問題をシャッフル
    this.shuffleKeys = this.shuffleArray(this.keys)

    // 初回アクセス時の処理終了
    this.isProcessing = false
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {String} 試験情報ID
     */
    eid () {
      return this.$route.params.eid
    },
    /**
     * @return {Object} 試験情報
     */
    exam () {
      return this.$store.getters['exams/exam'](this.eid)
    },
    /**
     * @return {Object} 試験IDに紐付く削除済みでない試験問題情報一覧
     */
    questions () {
      return this.$store.getters['questions/questions'](this.eid)
    },
    /**
     * @return {String[]} 試験問題のキー配列
     */
    keys () {
      return Object.keys(this.questions)
    },
    /**
     * @return {Object} 試験問題情報
     */
    question () {
      return this.questions[this.shuffleKeys[this.num]]
    }
  },
  methods: {
    /**
     * 戻る
     */
    back () {
      this.$router.push({ name: 'Exam' })
    },
    /**
     * 遷移確認ポップアップを表示する
     */
    backPopup () {
      this.openPopup = true
    },
    /**
     * 遷移確認ポップアップを閉じる
     */
    closePopup () {
      this.openPopup = false
    },
    /**
     * 試験開始
     */
    startExam () {
      this.isStart = true
    },
    /**
     * 解答を記録する
     * @param {Boolean} answer ユーザーの解答
     */
    pushAnswer (answer) {
      this.isAnswered = true
      this.answerHistory[this.shuffleKeys[this.num]] = answer
    },
    /**
     * 試験の採点
     * @return {Number} 試験の点数
     */
    score () {
      // 正答数
      const correctAnswer = Object.keys(this.answerHistory).reduce((score, qid) => {
        return this.answerHistory[qid] === this.questions[qid].correct ? score + 1 : score
      }, 0)
      // 1問あたりの点数
      const points = 100 / Object.keys(this.questions).length
      // 点数 = 正答数 * 1問あたりの点数
      return Math.round(correctAnswer * points)
    },
    /**
     * 次の試験問題を表示する
     */
    async nextQuestion () {
      // 最後の問題が終わったら、解答を保存して結果画面に遷移
      if (this.num + 1 === Object.keys(this.questions).length) {
        // 結果の保存に時間がかかるため処理中にしておく
        this.isProcessing = true

        const promises = []
        // 解答情報の更新
        Object.keys(this.answerHistory).map(qid => {
          const payload = {
            eid: this.eid,
            qid: qid,
            params: {
              uid: this.uid,
              answer: this.answerHistory[qid],
              isCorrect: this.answerHistory[qid] === this.questions[qid].correct,
              createdAt: new Date()
            }
          }
          promises.push(this.$store.dispatch('answers/addAnswer', payload))
        })

        // 結果情報の更新
        const payload = {
          eid: this.eid,
          uid: this.uid,
          params: {
            times: 1,
            full: this.score() === 100 ? 1 : 0,
            best: this.score(),
            updatedAt: new Date()
          }
        }
        const result = this.$store.getters['results/result'](this.eid)
        if (!result) {
          // 初回であれば作成日を追加する
          payload.params.createdAt = new Date()
        } else {
          // 2回目以降であれば過去の結果を考慮
          payload.params.times += result.times
          payload.params.full += result.full
          payload.params.best = this.score() > result.best ? this.score() : result.best
        }
        promises.push(this.$store.dispatch('results/setResult', payload))
        if (promises.length > 0) await Promise.all(promises)

        // 遷移前に回答順をstoreに保存
        this.$store.commit('questions/setAnswerOrderedQIDs', this.shuffleKeys)

        this.$router.push({ name: 'ExamResult', params: { eid: this.eid } })
      }

      // 次の問題へ
      this.isAnswered = false
      this.num++
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.exam-question {
  margin: $header_height 0 0;
  text-align: center;
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: $header_height;
    width: 100vw;
    background-color: #e0e0e0;
    z-index: 100;
    &__back {
      position: absolute;
      top: 0;
      left: 26px;
      bottom: 0;
      margin: auto;
      color: #a5a5a5;
      &.v-btn:not(.v-btn--round).v-size--default {
        height: 27px;
        min-width: auto;
        padding: 0;
      }
      &__icon {
        font-size: 27px;
      }
    }
    &__title {
      color: #393939;
      font-size: 20px;
      font-weight: normal;
      line-height: 30px;
      text-align: center;
    }
  }
}
</style>
