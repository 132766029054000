/**
 * original function library
 * [usage]
 * import array from '@/assets/lib/array'
 * mixins: [array]
 */
import Vue from 'vue'

export default Vue.extend({
  methods: {
    /**
     * 配列の順序をシャッフする
     * @param {Array} array シャッフルする配列
     * @return {Array} シャッフルした配列
     */
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [array[i], array[rand]] = [array[rand], array[i]]
      }
      return array
    }
  }
})
