<template>
  <div class="content">
    <div class="content__question">
      <p class="content__question__text">
        <span class="content__question__text__bold">Câu hỏi:<br></span>
        {{ question.question }}
      </p>
    </div>
    <div class="content__figure">
      <div class="content__figure__empty" v-if="!question.figure">
        <img class="content__figure__empty__img" src="@/assets/img/frame-landscape.svg" />
        <p class="content__figure__empty__text">No image available</p>
      </div>
      <img class="content__figure__img" v-else :src="question.figure" />
    </div>
    <div class="content__btn">
      <v-btn class="content__btn__item--true" depressed @click="$emit('pushAnswer', true)">
        <p class="content__btn__item__text">あっています</p>
      </v-btn>
      <v-btn class="content__btn__item--false" depressed @click="$emit('pushAnswer', false)">
        <p class="content__btn__item__text">まちがっています</p>
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    // 試験問題情報
    question: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.content {
  min-height: calc( 100vh - #{$header_height} );
  padding: 35px 20px 40px;
  background-color: #f6f6f6;
  &__question {
    padding: 30px 12px;
    border-radius: 5px;
    background-color: white;
    &__text {
      margin: 0;
      text-align: left;
      font-size: 15px;
      line-height: 20px;
      &__bold {
        font-weight: bold;
      }
    }
  }
  &__figure {
    margin-top: 10px;
    padding: 16px 0;
    background-color: white;
    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 58vw;
      &__img {
        width: 22vw;
      }
      &__text {
        margin: 5px 0 0;
        font-size: 15px;
        line-height: 20px;
        color: #e0e0e0;
      }
    }
    &__img {
      min-height: 58vw;
      width: 58vw;
      object-fit: contain;
      vertical-align: bottom;
    }
  }
  &__btn {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    &__item {
      flex: 1;
      min-width: 0;
      font-size: 20px;
      line-height: 27px;
      &.theme--light.v-btn:not(.v-btn--flat) {
        background-color: white;
      }
      &.v-btn:not(.v-btn--round){
        height: 125px;
      }
      &--true {
        @extend .content__btn__item;
        margin-right: 6px;
      }
      &--false {
        @extend .content__btn__item;
        margin-left: 6px;
      }
      &__text {
        width: 43vw;
        margin: 0;
        white-space: normal;
      }
    }
  }
}
</style>
