<template>
  <div class="judge">
    <div class="judge__symbol">
      <img v-if="answer === question.correct" src="@/assets/img/circle-outline.svg" />
      <img v-else src="@/assets/img/cancel.svg" />
    </div>
    <div class="judge__description">
      <v-btn depressed class="judge__description__btn" @click="$emit('next')">
        Tiếp theo
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <p class="judge__description__text">【Đáp án đúng】<br>{{ question.correct ? 'あっています' : '間違っています' }}</p>
      <p class="judge__description__text">【Giải thích】<br>{{ question.description }}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    // 解答
    answer: {
      type: Boolean,
      required: true
    },
    // 問題情報
    question: {
      type: Object,
      required: true
    }
  },
  methods: {
    /**
     * examページへ戻る
     */
    back () {
      this.$router.push({ name: 'Exam' })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.judge {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc( 100vh - #{$header_height} );
  width: 100%;
  z-index: 5;
  &__symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: 0;
  }
  &__description {
    position: relative;
    padding: 24px 21px;
    text-align: left;
    color: white;
    background-color: #ffa758;
    &__btn {
      position: absolute;
      top: -50px;
      right: $main_padding;
      margin-left: auto;
      width: 100px;
      font-size: 15px;
      line-height: 20px;
      color: white;
      border-radius: 50%;
      text-transform:none;
      &.v-btn:not(.v-btn--round) {
        height: 100px;
      }
      &.theme--light.v-btn:not(.v-btn--flat) {
        background-color: #ff9d44;
      }
      .v-icon {
        font-size: 40px;
      }
    }
    &__text {
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 20px;
    }
  }
}
</style>

<style lang="scss">
// vuetifyjs用

.judge {
  &__description {
    &__btn {
      .v-btn__content {
        flex-direction: column;
      }
    }
  }
}
</style>
