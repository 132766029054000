<template>
  <div class="start">
    <p class="start__name">{{ examName }}</p>
    <v-btn class="start__btn--start" depressed @click="$emit('start')">Bắt đầu</v-btn>
    <v-btn class="start__btn--back" depressed @click="$router.push({ name: 'Exam' })">Trở về</v-btn>
  </div>
</template>

<script>

export default {
  props: {
    // 試験名
    examName: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * examページへ戻る
     */
    back () {
      this.$router.push({ name: 'Exam' })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.start {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: calc( 100vh - #{$header_height} );
  padding: $header_height 0;
  background-color: white;
  &__name {
    width: 72%;
    margin: 0;
    font-size: 25px;
    line-height: 34px;
    font-weight: bold;
    word-wrap: break-word;
  }
  &__btn {
    display: block;
    width: 72%;
    margin: 0 auto;
    color: white;
    border-radius: 36px;
    text-transform:none;
    &.v-btn:not(.v-btn--round) {
      height: 44px;
    }
    &--start {
      @extend .start__btn;
      margin-top: 90px;
      &.theme--light.v-btn:not(.v-btn--flat) {
        background-color: #ff9d44;
      }
    }
    &--back {
      @extend .start__btn;
      margin-top: 15px;
      &.theme--light.v-btn:not(.v-btn--flat) {
        background-color: #d2d2d2;
      }
    }
  }
}
</style>
